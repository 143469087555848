<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <section class="section is-main-section">

      <div class="card has-table has-mobile-sort-spaced">
        <header class="card-header">
          <p class="card-header-title">
            <b-icon icon="account-multiple" custom-size="default" />
            Proposal
          </p>
        </header>
        <div class="card-content" style="padding: 12px" v-if="!isLoading">
          <template v-if="!isBuka">
            Pendaftaran Proposal Dibuka Pada Tanggal {{ setting.buka }} - {{ setting.tutup }}
          </template>
          <template v-else>
            <b-steps
                v-model="activeStep"
                :animated="true"
                :rounded="true"
                :has-navigation="false">
                <b-step-item step="1" label="Bukti Bayar" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">Upload Bukti Bayar</h1>
                    <div class="has-text-centered" v-if="!isPaymentUploaded">
                      <p> Upload Bukti Bayar ke Google drive & isi link file dibawah</p>
                      <form @submit.prevent="submitBayar">
                        <b-field label="Link Bukti Bayar" horizontal>
                          <b-input
                            v-model="form.file"
                            placeholder="http://"
                            required
                          />
                        </b-field>
                        <b-field label="Link KHS" horizontal>
                          <b-input
                            v-model="form.khs"
                            placeholder="http://"
                            required
                          />
                        </b-field>
                        <b-field label="Pilih Tahun Akademik" horizontal>
                          <v-select v-model="form.akademik_id" label="name" :options="akademik" :reduce="x => x.id">
                            <template #search="{attributes, events}">
                              <input
                                class="vs__search"
                                :required="!form.akademik_id"
                                v-bind="attributes"
                                v-on="events"
                              />
                            </template>
                          </v-select>
                        </b-field>
                        <b-field label="Catatan" horizontal>
                          <b-input  type="textarea"  v-model="form.note"></b-input>
                        </b-field>
                        <b-field style="text-align:right" horizontal>
                          <b-button
                            type="is-primary"
                            :loading="isLoading"
                            native-type="submit"
                            >Submit</b-button
                          >
                        </b-field>
                      </form>
                    </div>
                    <div class="has-text-centered" v-else>
                      <p> Pembayaran sedang dalam proses pengecekan</p>
                    </div>
                </b-step-item>

                <b-step-item step="2" label="Upload Proposal" :clickable="isStepsClickable">
                    <h1 class="title has-text-centered">Upload Proposal</h1>
                    <div class="has-text-centered">
                      <p> Upload Proposal ke Google drive & isi link file dibawah</p>
                      <form @submit.prevent="submitProposal">
                        <b-field label="Link Proposal" horizontal>
                          <b-input
                            v-model="form.file"
                            placeholder="http://"
                            required
                          />
                        </b-field>
                        <b-field label="Judul Proposal" horizontal>
                          <b-input
                            v-model="form.judul"
                            placeholder=""
                            required
                          />
                        </b-field>
                        <b-field label="Pilih Tahun Akademik" horizontal>
                          <v-select v-model="form.akademik_id" label="name" :options="akademik" :reduce="x => x.id"></v-select>
                        </b-field>
                        <b-field label="Dosen Pembimbing" horizontal>
                          <v-select v-model="form.pembimbing1_id" label="name_quota" :options="dosenFiltered" :reduce="x => x.id"></v-select>
                        </b-field>
                        <b-field style="text-align:right" horizontal>
                          <b-button
                            type="is-primary"
                            :loading="isLoading"
                            native-type="submit"
                            >Submit</b-button
                          >
                        </b-field>
                      </form>
                    </div>
                </b-step-item>

                <b-step-item step="3" label="Finish" :clickable="isStepsClickable" disabled>
                    <h1 class="title has-text-centered">Finish</h1>
                    <div class="has-text-centered">
                      Proposal Berhasil di Upload silahkan cek Dashboard & Email untuk informasi selanjutnya
                    </div>
                </b-step-item>
            </b-steps>
          </template>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="false" v-model="isLoading" ></b-loading>
  </div>
</template>

<script>
// @ is an alias to /src
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import axios from 'axios'

export default {
  name: 'Home',
  components: {
    // CardWidget,
    // Tiles,
    HeroBar,
    TitleBar,
    vSelect
  },
  data () {
    return {
      oriData: [],
      data: [],
      form: {},
      setting: false,
      searchWord: null,
      isLoading: true,
      paginated: false,
      perPage: 10,
      checkedRows: [],
      akademik: [],
      dosen: [],
      payments: [],
      proposals: [],
      isStepsClickable: false
    }
  },
  computed: {
    titleStack () {
      return ['Dashboard']
    },
    currentKBK () {
      return this.$store.getters.currentKBK
    },
    dosenFiltered () {
      const filter = this.dosen.length ? this.dosen.filter((x) => {
        return x.kbk === this.currentKBK.name
      }) : []

      return parseInt(this.setting.isFilterByKonsentrasi) ? filter : this.dosen
    },
    isBuka () {
      if (this.setting) {
        const d1 = new Date(this.setting.buka).getTime()
        const d2 = new Date(this.setting.tutup).getTime()
        const now = new Date().getTime()

        console.log(d1, d2, now)

        if (now > d1 && now < d2) {
          return true
        }
      }

      return false
    },
    isPaymentUploaded () {
      let hasil = false

      if (this.payments) {
        this.payments.forEach(x => {
          // 1 = pengajuan
          if ((x.status_id === 1 || x.status_id === '1')) {
            hasil = true
          }
        })
      }

      return hasil
    },
    activeStep () {
      let payment = false
      let proposal = false

      if (this.payments) {
        this.payments.forEach(x => {
          // 1 = pengajuan
          if ((x.status_id === 2 || x.status_id === '2')) {
            payment = true
          }
        })
      }

      if (this.proposals) {
        this.proposals.forEach(x => {
          // if there is proposal accepted
          if (x.status_id === 2 || x.status_id === '2') {
            proposal = true
            payment = true
          }

          if ((x.status_id === 1 || x.status_id === '1')) {
            proposal = true
          }
        })
      }

      console.log(payment, proposal)

      if (payment && proposal) {
        return 2
      } else if (payment) {
        return 1
      }

      return 0
    }
  },
  async mounted () {
    await axios
      .get('/mahasiswa_setting_proposal')
      .then((r) => {
        if (r.data.data.length) {
          this.setting = r.data.data[0]
        }
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })

    await axios
      .get('/mahasiswa_payments')
      .then((r) => {
        this.payments = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })

    await axios
      .get('/mahasiswa_proposals')
      .then((r) => {
        this.proposals = r.data.data
      })
      .catch((e) => {
        this.isLoading = false
        this.$buefy.toast.open({
          message: `Error: ${e.message}`,
          type: 'is-danger'
        })
      })

    await this.getData()

    this.isLoading = false
  },
  methods: {
    async getData () {
      await axios
        .get('/mahasiswa_akademik')
        .then((r) => {
          this.akademik = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
      await axios
        .get('/mahasiswa_dosen')
        .then((r) => {
          this.dosen = r.data.data
        })
        .catch((e) => {
          this.isLoading = false
          this.$buefy.toast.open({
            message: `Error: ${e.message}`,
            type: 'is-danger'
          })
        })
    },
    search (word) {
      if (word === null || word === '') {
        this.getData()
        return false
      }

      console.log(`search for ${word}`)
      const result = this.oriData.filter(obj => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(obj)) {
          // console.log(`${word} in ${value} ${String(value).toLowerCase().indexOf(word)}`)
          if (String(value).toLowerCase().indexOf(word.toLowerCase()) >= 0) {
            return true
          }
        }
      })

      this.data = result
    },
    handleResponse (r) {
      console.log(r.data)
      this.isLoading = false

      this.$buefy.snackbar.open({
        message: (typeof r.data.message === 'object') ? `error: ${r.data.message.join(', ')}` : r.data.message,
        type: r.data.status === 'ok' ? 'is-primary' : 'is-danger',
        queue: false
      })

      if (r.data.status === 'ok') {
        this.$router.go()
      }
    },
    handleError (e) {
      this.isLoading = false
      this.$buefy.toast.open({
        message: `Error: ${e.message}`,
        type: 'is-danger',
        queue: false
      })
    },
    submitBayar () {
      const data = this.form

      this.isLoading = true

      axios
        .post('/mahasiswa_payments', data)
        .then(this.handleResponse)
        .catch(this.handleError)
    },
    submitProposal () {
      const data = this.form

      this.isLoading = true

      axios
        .post('/mahasiswa_proposals', data)
        .then(this.handleResponse)
        .catch(this.handleError)
    }
  }
}
</script>
